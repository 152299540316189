<template>
  <div class="Investment_cont">
    <!-- 办公区租赁 -->
    <el-card class="lease">
      <div class="lease_title">
        <h2>基本情况</h2>
      </div>
      <el-form :model="group_manager" ref="officeRef" label-width="100px" size="small" class="leaseFrom">
        <el-form-item label="证载房产总面积:" prop="asset_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.asset_area" autocomplete="off"></el-input>
          <span> 万㎡</span>
        </el-form-item>
        <el-form-item label="总租赁面积:" prop="full_rent_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.full_rent_area" autocomplete="off"></el-input>
          <span> 万㎡</span>
        </el-form-item>
        <el-form-item label="年租金收入:" prop="rental_income" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.rental_income" autocomplete="off"></el-input>
          <span> 万元</span>
        </el-form-item>
        <el-form-item label="企业数:" prop="company_nm" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.company_nm" autocomplete="off"></el-input>
          <span> 家</span>
        </el-form-item>
        <el-form-item label="员工数:" prop="employee_nm" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.employee_nm" autocomplete="off"></el-input>
          <span> 人</span>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="lease">
      <div class="lease_title">
        <h2>资产概况</h2>
      </div>
      <el-form :model="group_manager" ref="officeRef" label-width="100px" size="small" class="leaseFrom">
        <el-form-item label="已租赁面积:" prop="rent_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.rent_area" autocomplete="off"></el-input>
          <span> 万㎡</span>
        </el-form-item>
        <el-form-item label="未租赁面积:" prop="not_rent_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.not_rent_area" autocomplete="off"></el-input>
          <span> 万㎡</span>
        </el-form-item>
        <el-form-item label="出租率:" prop="rent_quote">
          <el-input type="number" disabled :value="calculation_rent_rate"></el-input>
          <span> %</span>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="lease">
      <div class="lease_title">
        <h2>资产项目</h2>
      </div>
      <el-form :model="group_manager" ref="officeRef" label-width="100px" size="small" class="leaseFrom">
        <el-form-item label="总项目数:" prop="all_project_nm" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.all_project_nm" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="运营项目数:" prop="operate_project_nm" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.operate_project_nm" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="在建项目数:" prop="build_project_nm" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.build_project_nm" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="lease">
      <div class="lease_title">
        <h2>产权分布</h2>
      </div>
      <el-form :model="group_manager" ref="officeRef" label-width="100px" size="small" class="leaseFrom">
        <el-form-item label="承租资产:" prop="leased_asset_nm" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.leased_asset_nm" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="面积:" prop="leased_asset_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.leased_asset_area" autocomplete="off"></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="占比:" prop="rent_quote">
          <el-input type="number" disabled :value="calculation_leased_asset_rate"></el-input>
          <span> %</span>
        </el-form-item>
      </el-form>
      <el-form :model="group_manager" ref="officeRef" label-width="100px" size="small" class="leaseFrom">
        <el-form-item label="托管资产:" prop="deposit_asset_nm" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.deposit_asset_nm" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="面积:" prop="deposit_asset_area" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
          <el-input type="number" @change="officeChange"  v-model.number="group_manager.deposit_asset_area" autocomplete="off"></el-input>
          <span> ㎡</span>
        </el-form-item>
        <el-form-item label="占比:" prop="rent_quote">
          <el-input type="number" disabled :value="calculation_deposit_asset_rate"></el-input>
          <span> %</span>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="operate">
      <div class="operate_title">
        <h2>项目面积分布及出租率</h2>
      </div>
      <!-- 第一条运营情况的标题 -->
      <div class="operTitle_cont">
        <span>项目名称</span><span>面积(㎡)</span><span>出租率</span>
      </div>
      <!-- 渲染添加的内容 -->
      <el-form ref="operateForm" v-for="(item, index) in gmad" :key="index + '_1'" :model="item" label-position="top" size="small" label-width="80px" class="operateForm">
        <div class="oper_input">
          <el-select @input="operateChange(item.id,item.park_id,item.area,item.rent_rate)" v-model="item.park_id" size="small">
            <el-option v-for="park in park_list" v-bind:key="park.id" :label="park.name" :value="park.id"></el-option>
          </el-select>
        </div>
        <div class="oper_input">
          <el-input :class="item.area == '' ? 'red_error' : ''" @change="operateChange(item.id,item.park_id,item.area,item.rent_rate)" v-model="item.area" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input :class="item.rent_rate == '' ? 'red_error' : ''" @change="operateChange(item.id,item.park_id,item.area,item.rent_rate)" v-model="item.rent_rate" size="small"></el-input>
        </div>
        <div class="form_btn">
          <el-button v-if="index >= 1" class="numberInput"  @click="removeChange(item.id)" type="text" icon="el-icon-minus"></el-button>
          <el-button class="numberInput"  @click="addChange()" type="text" icon="el-icon-plus"></el-button>
        </div>
      </el-form>
    </el-card>
    <el-card class="operate">
      <div class="operate_title">
        <h2>企业行业分布</h2>
      </div>
      <!-- 第一条运营情况的标题 -->
      <div class="operTitle_cont">
        <span>行业名称</span><span>企业数量（家）</span><span>分布占比(%)</span>
      </div>
      <el-form ref="operateForm" v-for="(item, index) in gmid" :key="index + '_1'" :model="item" label-position="top" size="small" label-width="80px" class="operateForm">
        <div class="oper_input">
          <el-input :class="item.industry_name == '' ? 'red_error' : ''" @change="operateChange2(item.id,item.industry_name,item.company_nm)" v-model="item.industry_name" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input :class="item.company_nm == '' ? 'red_error' : ''" @change="operateChange2(item.id,item.industry_name,item.company_nm)" v-model="item.company_nm" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input disabled :value="calculation_grim_rate[index]" size="small"></el-input>
        </div>
        <div class="form_btn">
          <el-button v-if="index >= 1" class="numberInput"  @click="removeChange2(item.id)" type="text" icon="el-icon-minus"></el-button>
          <el-button class="numberInput"  @click="addChange2()" type="text" icon="el-icon-plus"></el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 办公区租赁的数据
      group_manager: {},
      park_list: [],
      gmad: [],
      gmid: []
    }
  },
  async created () {
    this.get_office()
    this.get_gmad()
    this.get_gmid()
    this.get_park_list()
  },
  computed: {
    // 出租率 已出租面积 / 办公配套面积
    calculation_rent_rate () {
      return Math.round(parseFloat(this.group_manager.rent_area) / (parseFloat(this.group_manager.rent_area) + parseFloat(this.group_manager.not_rent_area)) * 10000) / 100
      // return 1 //Math.round(this.dahang.rent_area / this.dahang.full_rent_area * 100)
    },
    calculation_leased_asset_rate () {
      return Math.round(this.group_manager.leased_asset_area / (this.group_manager.leased_asset_area + this.group_manager.deposit_asset_area) * 10000) / 100
    },
    calculation_deposit_asset_rate () {
      return Math.round(this.group_manager.deposit_asset_area / (this.group_manager.leased_asset_area + this.group_manager.deposit_asset_area) * 10000) / 100
    },
    calculation_grim_rate () {
      const data = {}
      let fullNm = 0
      this.gmid.forEach((obj, k) => {
        fullNm += obj.company_nm * 1
      })
      this.gmid.forEach((obj, k) => {
        data[k] = Math.round(obj.company_nm / fullNm * 100)
      })
      return data
    }
  },
  methods: {
    // 获取办公区租赁的数据
    async get_office () {
      const { data: res } = await this.$http.get('group_manager')
      this.group_manager = res.data
    },
    async get_park_list () {
      const { data: res } = await this.$http.get('park_list')
      this.park_list = res
    },
    async get_gmad () {
      const { data: res } = await this.$http.get('group_manager_area_distribute')
      this.gmad = res.data
    },
    async get_gmid () {
      const { data: res } = await this.$http.get('group_manager_industry_distribute')
      this.gmid = res.data
    },
    // 修改租赁的情况
    officeChange () {
      this.$refs.officeRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('group_manager', this.group_manager)
        if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
        // this.get_office()
      })
    },
    async operateChange (id, parkId, area, rentRate) {
      const { data: res } = await this.$http.post('edit_group_manager_area_distribute', {
        id, parkId, area, rentRate
      })
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
    },
    async operateChange2 (id, industryName, companyNm) {
      const { data: res } = await this.$http.post('edit_group_manager_industry_distribute', {
        id, industryName, companyNm
      })
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
    },
    async removeChange (id) {
      const { data: res } = await this.$http.post('delete_group_manager_area_distribute', {
        id
      })
      this.get_gmad()
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
    },
    async removeChange2 (id) {
      const { data: res } = await this.$http.post('delete_group_manager_industry_distribute', {
        id
      })
      this.get_gmid()
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
    },
    async addChange () {
      const { data: res } = await this.$http.post('new_group_manager_area_distribute', {
        create: 'create'
      })
      this.gmad.push(res.data)
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    async addChange2 () {
      const { data: res } = await this.$http.post('new_group_manager_industry_distribute', {
        create: 'create'
      })
      this.gmid.push(res.data)
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    }
  }
}
</script>

<style lang="less" scoped>
.Investment_cont{
  padding: 1rem;
  .lease2{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem;
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 30%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
        }
      }
    }
  }
  .operate{
    margin-bottom: 1rem;
    .operTitle{
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 2rem;
      padding: 1rem 0;
    }
    .parking_title,.operate_title,.facilities_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .parkingFrom{
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      .el-form-item{
        width: 20%;
        text-align: center;
        .el-input{
          width: 75%;
        }
      }
    }
    .operateForm{
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-form-item,.oper_input{
        width: 20%;
        text-align: center;
      }
      .form_btn{
        width: 10%;
        display: flex;
        font-size: 1.25rem;
      }
    }
    .operTitle_cont{
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        width: 23%;
        font-size: 14px;
        color: #606266;
        text-align: center;
        padding: 0.5rem 0;
      }
    }
  }
  .lease{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 1rem;
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 16%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
        }
      }
    }
  }
  .detailed{
    margin-bottom: 1rem;
    .detailed_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .detailed_box{
      width: 50%;
      display: flex;
      flex-direction: column;
      .detailed_box_title{
        display: flex;
        justify-content:space-around;
        padding: 1rem 0;
        span{
          width: 20%;
          text-align: center;
        }
      }
      .detailed_items{
        display: flex;
        justify-content: space-between;
        // margin-bottom: 1rem;
        text-align: center;
        .item{
          width: 20%;
          display: flex;
          justify-content: center;
          .el-input{
            width: 80%;
            .el-input--small .el-input__inner{
              text-align: center;
            }
          }
        }
      }
    }
  }
  .Industry{
    .Industry_title_btn{
      display: flex;
      align-items: center;
      .el-button{
        width: 7%;
        padding: 8px;
        font-size: 14px;
        border-radius: 5px;
        align-self: flex-start;
        margin-left: 2rem;
      }
    }
    .Industry_table{
      margin: 1rem 0;
    }
  }
  .dialogIndustry{
    .el-form{
      width: 80%;
      .el-select{
        width: 100%;
      }
    }
  }
}
</style>
